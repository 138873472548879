import React from 'react';

import { capitalize } from 'lodash-es';

import { Box, Text } from '@clutter/clean';
import {
  Location__City,
  Location__Neighborhood,
  Location__State,
} from '@graphql/platform';
import { neighborhoodURL } from '@root/components/locations/routes';
import { Spacer } from '@shared/spacer';

import { LocationLinksLayout } from './location_links_layout';

export const NeighborhoodLinks: React.FC<{
  state: Pick<Location__State, 'name' | 'slug' | 'code'>;
  city: Pick<Location__City, 'name' | 'slug'>;
  neighborhoods: ReadonlyArray<
    Pick<Location__Neighborhood, 'id' | 'name' | 'slug'>
  >;
  service: 'moving' | 'storage';
}> = ({ state, city, neighborhoods, service }) => {
  const formattedService = capitalize(service);
  return (
    <Box textAlign="center">
      <LocationLinksLayout>
        {neighborhoods.map((n) => (
          <LocationLinksLayout.Link
            key={n.id}
            to={neighborhoodURL(state.slug, city.slug, n.slug, service)}
          >
            {formattedService} in {n.name}, {state.code}
          </LocationLinksLayout.Link>
        ))}
      </LocationLinksLayout>
      {state.name.toLowerCase() == 'illinois' && (
        <>
          <Spacer height="32px" />
          <Text.Body>Clutter Inc.</Text.Body>
          <Text.Body>IllCC MC 219367</Text.Body>
        </>
      )}
    </Box>
  );
};
